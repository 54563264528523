<template>
    <div>
    <commonTable
      :tableData="tableData"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      :currentPage="pageNum"
      :loading="loading"
      :total="total"
    >
      <template v-slot:table>
        <el-table-column prop="avatarUrl" align="center" label="头像">
          <template slot-scope="scope">
              <img class="avatar" :src="scope.row.avatarUrl" />
          </template>
        </el-table-column>
        <el-table-column prop="nickeName" align="center" label="昵称"></el-table-column>
        <el-table-column prop="num" align="center" label="扫码次数"></el-table-column>
        <el-table-column prop="integral" align="center" label="积分"></el-table-column>
        <el-table-column prop="phone" align="center" label="手机号"></el-table-column>
        <el-table-column prop="" align="center" label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="goRecord(scope.row)">积分记录</el-button>
            <el-button type="text" @click="goScanRecord(scope.row)">扫码记录</el-button>
          </template>
        </el-table-column>
      </template>
    </commonTable>
    </div>
</template>
<script>
import commonTable from "@/components/common/commonTable";
import { get, post } from "@/utils/request";
export default {
  name: "vipManage",
  components: {
    commonTable,
  },
  data() {
    return {
      pageNum: 1,
      pageSize: 10,
      total: 0,
      tableData: []
    }
  },
  created() {
      this.getTableData()
  },
  methods: {
    handleSizeChange(num) {
      this.pageSize = num;
      this.pageNum = 1;
      this.getTableData();
    },
    handleCurrentChange(num) {
      this.pageNum = num;
      this.getTableData();
    },
    getTableData() {
      let params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      }
      get("/dealer/memberList", params).then((res) => {
        this.tableData = res.list;
        this.total = res.total
      });
    },
    goRecord(data) {
      this.$router.push({
        path:`/member/integral`,
        query: {
          userId : data.userId
        }
      })
    },
    goScanRecord(data) {
      this.$router.push({
        path:`/member/scan`,
        query: {
          userId : data.userId
        }
      })
    }

  }
}
</script>
<style scoped>
.avatar{
    width: 30px;
    height: 30px;
    border-radius: 50%;
}
</style>